// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-solution-template-js": () => import("/Users/VicenteRincon/Web/chata/src/templates/solution-template.js" /* webpackChunkName: "component---src-templates-solution-template-js" */),
  "component---src-pages-404-js": () => import("/Users/VicenteRincon/Web/chata/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/VicenteRincon/Web/chata/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-autoql-js": () => import("/Users/VicenteRincon/Web/chata/src/pages/autoql.js" /* webpackChunkName: "component---src-pages-autoql-js" */),
  "component---src-pages-datamessanger-js": () => import("/Users/VicenteRincon/Web/chata/src/pages/datamessanger.js" /* webpackChunkName: "component---src-pages-datamessanger-js" */),
  "component---src-pages-index-js": () => import("/Users/VicenteRincon/Web/chata/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("/Users/VicenteRincon/Web/chata/src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-portafolio-js": () => import("/Users/VicenteRincon/Web/chata/src/pages/portafolio.js" /* webpackChunkName: "component---src-pages-portafolio-js" */),
  "component---src-pages-works-js": () => import("/Users/VicenteRincon/Web/chata/src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */)
}

