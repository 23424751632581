module.exports = [{
      plugin: require('/Users/VicenteRincon/Web/chata/node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/VicenteRincon/Web/chata/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/VicenteRincon/Web/chata/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-148518036-1","head":true,"anonymize":true},
    },{
      plugin: require('/Users/VicenteRincon/Web/chata/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-148518036-1"},
    },{
      plugin: require('/Users/VicenteRincon/Web/chata/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
